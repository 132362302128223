import React from "react";
import { Form, FormGroup, InputGroup, Input } from "reactstrap";
import { useQuery, gql } from "@apollo/client";

const CHARACTERS_QUERY_LIGHT = gql`
  query GetCharacters($searchstring: String) {
    characters(page: 1, filter: { name: $searchstring }) {
      results {
        name
      }
    }
  }
`;
//returns only 20 results

type SearchBarProps = {
  onSearch: (value: string) => void;
};

export const SearchBar: React.FunctionComponent<SearchBarProps> = (props) => {
  const maxSuggestion = 6; //only show 6 suggestion under search bar

  const [val, setVal] = React.useState<string>("");
  const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
  const [activeSuggestion, setActiveSuggestion] = React.useState<number>(-1);
  const { loading, error, data } = useQuery(CHARACTERS_QUERY_LIGHT, {
    variables: { searchstring: val },
  });

  const suggestions = data
    ? data.characters.results
        .slice(0, maxSuggestion)
        .map((character: any) => character.name)
    : [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVal(event.target.value);
    if (event.target.value.length >= 2) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };
  const handleKey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (activeSuggestion === -1) {
        props.onSearch(val);
      } else {
        const selected_value = suggestions[activeSuggestion];
        setVal(selected_value);
        props.onSearch(selected_value);
        setActiveSuggestion(-1);
      }
      setShowSuggestions(false);
    } else if (event.key === "ArrowUp") {
      if (activeSuggestion === -1) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (event.key === "ArrowDown") {
      if (activeSuggestion === maxSuggestion - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const clicked_value = event.currentTarget.innerText;
    setVal(clicked_value);
    setActiveSuggestion(-1);
    setShowSuggestions(false);
    props.onSearch(clicked_value);
  };

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup>
          <InputGroup>
            <Input
              type="text"
              value={val}
              onChange={handleChange}
              onKeyDown={handleKey}
              placeholder="Search Character by Name"
            />

            {!error && !loading && showSuggestions ? (
              <ul className="suggestions">
                {suggestions.map((name: string, index: number) => (
                  <li
                    className={
                      (activeSuggestion === index ? "suggestion-active " : "") +
                      "suggestion-item"
                    }
                    key={name}
                    onClick={onClick}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            ) : null}
          </InputGroup>
        </FormGroup>
      </Form>
    </div>
  );
};
