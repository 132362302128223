import React from "react";
import "./App.css";
import { SearchBar } from "./components/searchbar";
import { Results } from "./components/results";
import { useQuery, gql } from "@apollo/client";
import { Container, Row, Col, Navbar, NavbarBrand, NavbarText } from "reactstrap";

import Amplify, { API, Auth }  from "aws-amplify";
import { AmplifySignOut, withAuthenticator } from "@aws-amplify/ui-react";
import * as config from "./aws-exports.json";

Amplify.configure({
  Auth: {
    region: config.userPoolRegion,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    identityPoolId: config.identityPoolID,
  },
  API: {
    endpoints: config.endpoints,
  },
});



const CHARACTERS_QUERY = gql`
  query GetCharacters($searchstring: String) {
    characters(page: 1, filter: { name: $searchstring }) {
      info {
        count
      }
      results {
        name
        image
      }
    }
  }
`;

function App() {
  const [searchstring, setSearchstring] = React.useState<string>("");

  const { loading, error, data } = useQuery(CHARACTERS_QUERY, {
    variables: { searchstring: searchstring },
  });

  const handleSearch = (value: string) => {
    setSearchstring(value);
  };

  const [userError, setUserError] = React.useState(null);
  const [userLoading, setUserLoading] = React.useState(true);
  const [username, setUsername] = React.useState('');

  React.useEffect(() => {

   /*  const myInit = { 
      headers: { 
        Authorization: `Bearer ${(Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    }; */

    Auth.currentSession().then(res=>{
      let accessToken = res.getAccessToken()
      let jwt = accessToken.getJwtToken()
      //You can print them to see the full objects
      console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
      console.log(`myJwt: ${jwt}`)
    })

 
  /*   API.get("api-one", "/test", null)
  .then(data => console.log(data.body));
 */
  },[])

  React.useEffect(() => {
    try {
      setUserError(null);
      setUserLoading(true);

      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setUsername(user.attributes.email);
        console.log(user);
        console.log(`Load additional settings for user: ${user.attributes.email}`);
        // TBD
      }).catch(err => setUserError(err));
    }
    catch (e) {
      setUserError(e);
    }
    finally {
      setUserLoading(false);
    }
  }, []);

  return (
    <div className="App">
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">Rick & Morty Search</NavbarBrand>
        <NavbarText>{username}</NavbarText>
      </Navbar>
      <Container>
      {/* {userInfo} */}
        <div style={{ height: "50px" }}></div>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <SearchBar onSearch={handleSearch} />
          </Col>
          <Col md={4}></Col>
        </Row>
        <div style={{ height: "50px" }}></div>
        <Row>
          <div style={{textAlign: "center"}}></div>
          {loading ? (
            "Loading..."
          ) : error ? (
            "Error!"
          ) : (
            <div>
              <Results characters={data.characters.results} />
              Showing {data.characters.results.length} of{" "}
              {data.characters.info.count} Results
            </div>
          )}
        </Row>
        <AmplifySignOut />
      </Container>
    </div>
  );
}

export default withAuthenticator(App);
