import React from "react";
import {
  CardColumns,
  Card,
  CardImg,
  CardBody,
  CardTitle,
} from "reactstrap";

type Character = {
  name: string;
  image: string;
};

type ResultsProps = {
  characters: Character[];
};

export const Results: React.FunctionComponent<ResultsProps> = (props) => {
  return (
    <div>
      <CardColumns style={{columnCount: 5}}>
        {props.characters.map((character) => (
          <Card>
            <CardImg
              top
              style={{ width: "100px", height: "100px" }}
              src={character.image}
              alt={"Image of " + character.name}
            />
            <CardBody>
              <CardTitle tag="h6">{character.name}</CardTitle>
            </CardBody>
          </Card>
        ))}
      </CardColumns>
    </div>
  );
};
